<template>
	<div class="media audio">
		<div ref="player_wrapper" class="player placeholder">
			<vue-plyr
				ref="plyr_audio"
				:options="{
					hideControls: false,
					controls: ['play', 'progress', 'current-time', 'volume'],
				}">
				<div class="plyr__audio-embed">
					<iframe :src="url" allowfullscreen allowtransparency allow="autoplay"></iframe>
				</div>
			</vue-plyr>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
	},

	mounted() {
		this.$refs.plyr_audio.player.on('ready', () =>
			this.$refs.player_wrapper.classList.remove('placeholder')
		)

		// Ugly fallback if 'ready' event is not fired
		setTimeout(() => {
			this.$refs.player_wrapper.classList.remove('placeholder')
		}, 5000)
	},

	methods: {
		pause() {
			this.$refs.plyr_audio.player.pause()
		},
	},
}
</script>

<style lang="scss" scoped>
.audio {
	position: relative;
	z-index: 1;

	.player {
		background: rgba($primary-color, 0.15);
		border: 2px solid rgba($primary-color, 0.25);
		border-radius: $border-radius;
		height: 60px;
		margin: 0 auto;
		padding: 10px;
		position: static;
		width: 100%;
	}

	::v-deep .plyr {
		position: static;
		margin-top: -1px;
	}

	::v-deep .plyr--video {
		background: none;
		overflow: visible;

		.plyr__controls {
			background: transparent;
			color: $black;
			margin: 0 auto;
			transform: translateY(-2px);
			width: 99%;
		}

		.plyr__video-embed {
			display: none;
		}
	}

	.placeholder {
		@include placeholder;
	}
}
</style>
