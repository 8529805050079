<template>
	<div class="lightbox" @click.self="$emit('collapse')">
		<Icon name="close" fill="black" @click="$emit('collapse')" />

		<LazyImage v-if="type === 'image'" :lazy-src="src" :alt="alt" />

		<Video v-if="type === 'video'" :url="src" />
	</div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue'
import Video from '@/components/mediaTypes/Video.vue'
import Icon from './Icon.vue'

export default {
	components: {
		LazyImage,
		Video,
		Icon,
	},

	props: {
		src: { type: String, required: true },
		type: { type: String, required: true },
		alt: { type: String },
	},
}
</script>

<style lang="scss" scoped>
.lightbox {
	align-items: center;
	background: rgba(white, 0.75);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;

	::v-deep .icon i {
		cursor: pointer;
		opacity: 0.65;
		position: fixed;
		right: 50px;
		top: 100px;
		transform: scale(125%);
		transition: $transition;
		user-select: none;

		&:hover {
			opacity: 0.85;
		}
	}

	.lazy-image {
		margin-bottom: 10vh;
	}

	img {
		border-radius: $border-radius * 2;
		max-height: 60vh;
		max-width: 90vh;
		user-select: none;
		width: auto;
	}

	.video {
		max-width: 900px;
		width: 90%;

		::v-deep.plyr {
			iframe {
				transform: scale(1);
			}
		}

		::v-deep.plyr__controls {
			padding-bottom: 25px;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}
</style>
