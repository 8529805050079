<template>
	<div class="media video">
		<div class="video-wrapper">
			<vue-plyr ref="plyr_video">
				<div class="plyr__video-embed">
					<iframe :src="url" allowfullscreen allowtransparency allow="autoplay"></iframe>
				</div>
			</vue-plyr>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
	},

	methods: {
		pause() {
			this.$refs.plyr_video.player.pause()
		},
	},
}
</script>

<style lang="scss" scoped>
.video {
	--plyr-color-main: #5f27cd;
	--plyr-video-progress-buffered-background: rgba(255, 255, 255, 0.25);
	max-width: 500px;
	margin: 0 auto;

	.video-wrapper {
		border-radius: $border-radius;
		overflow: hidden;

		::v-deep .plyr {
			transform: scale(1.01);
		}
	}
}
</style>
