<template>
	<div class="start-screen">
		<transition name="fade" appear>
			<router-link to="/play" class="back-link">
				<Icon name="arrow-back" />
				<span>{{ translate('back') }}</span>
			</router-link>
		</transition>

		<transition name="fade-slide-up" appear>
			<div
				:class="[
					'hero',
					randomCoverImagePattern(),
					{ imageMissing: quiz.cover_url === null || quiz.cover_url === '' },
				]"
			>
				<div class="cover-image">
					<img v-if="quiz.cover_url" :src="quiz.cover_url" alt="Cover Image" />
				</div>

				<div class="inner-content">
					<h2>{{ quiz.title }}</h2>
					<p v-if="quiz.description" class="description">{{ quiz.description }}</p>
					<div class="meta">
						<div>
							<p class="made-by">
								{{ translate('madeBy') }} {{ createdByMe && !preview ? translate('you') : '' }}
								<User v-if="!createdByMe || preview" :id="quiz.created_by" :clickable="true" />
							</p>
						</div>
						<div>
							<Icon name="question-mark-circle" class="purple" width="20" />
							<p>{{ quiz.questions.length }} {{ translate('myQuizzes.questions') }}</p>
						</div>
						<div>
							<Icon name="checkmark-circle-2" class="green" width="20" />
							<p>{{ translate('myQuizzes.completedBy', null, quiz.completedBy) }}</p>
						</div>
						<button
							v-if="quiz.public || environment !== 'production'"
							class="big icon-right"
							:class="{ disabled: createdByMe }"
							@click="$emit('start')"
						>
							{{
								createdByMe && !preview
									? translate('quiz.notice.cantPlayOwnQuiz')
									: translate('quiz.start')
							}}<Icon
								v-if="!createdByMe || preview"
								name="play-circle-outline"
								class="white"
								:width="preview ? '22' : '24'"
							/>
						</button>
					</div>
				</div>
			</div>
		</transition>

		<div v-if="!quiz.public && !preview" class="alert red">
			<Icon name="alert-triangle" />
			<p v-html="translate('quiz.notice.privacyPrivate')"></p>
		</div>

		<router-link v-if="createdByMe" :to="`/builder/${quiz.qid}`">{{
			translate('quiz.editQuiz')
		}}</router-link>

		<transition name="fade" appear>
			<div v-if="!preview" style="margin-top: 100px; transition-delay: .5s; text-align: center;">
				<h4 style="margin-bottom: 20px;">{{ translate('otherQuizzes') }}</h4>
				<QuizSuggestions :transition-delay="0.5" />
			</div>
		</transition>
	</div>
</template>

<script>
import { auth } from '@/lib/supabase'

import QuizSuggestions from '@/components/QuizSuggestions'
import User from '@/components/User'
import Icon from './Icon.vue'

export default {
	components: {
		QuizSuggestions,
		User,
		Icon,
	},

	props: {
		quiz: {
			type: Object,
			required: true,
		},
		preview: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		createdByMe() {
			return this.quiz.created_by === auth.user().id
		},

		environment() {
			return this.$store.getters.environment
		},
	},

	mounted() {
		if (this.environment !== 'production') {
			console.log({ quiz: this.quiz })
		}
	},

	methods: {
		randomCoverImagePattern() {
			const patterns = ['zigzag3d', 'circles', 'isometric']
			return patterns[Math.floor(Math.random() * patterns.length)]
		},
	},
}
</script>

<style lang="scss" scoped>
.start-screen {
	margin: 0 0 100px;
	position: relative;

	.back-link {
		transform: translateY(-200%);
		position: absolute;

		@include mobile {
			display: none;
		}
	}

	.hero {
		padding: 50px 50px 25px;
		position: relative;

		&.imageMissing {
			&.zigzag3d {
				.cover-image::before {
					background-color: #000000;
					opacity: 0.5;
					background: linear-gradient(135deg, #21212155 25%, transparent 25%) -15px 0/ 30px 30px,
						linear-gradient(225deg, #212121 25%, transparent 25%) -15px 0/ 30px 30px,
						linear-gradient(315deg, #21212155 25%, transparent 25%) 0px 0/ 30px 30px,
						linear-gradient(45deg, #212121 25%, #000000 25%) 0px 0/ 30px 30px;
				}
			}

			&.circles {
				.cover-image::before {
					background-color: #6e6e6e;
					opacity: 0.5;
					background-image: radial-gradient(circle at center center, #000000, #6e6e6e),
						repeating-radial-gradient(
							circle at center center,
							#000000,
							#000000,
							9px,
							transparent 18px,
							transparent 9px
						);
					background-blend-mode: multiply;
				}
			}

			&.isometric {
				.cover-image::before {
					background-color: #000000;
					opacity: 0.5;
					background-image: linear-gradient(
							30deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							150deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							30deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							150deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							60deg,
							#21212177 25%,
							transparent 25.5%,
							transparent 75%,
							#21212177 75%,
							#21212177
						),
						linear-gradient(
							60deg,
							#21212177 25%,
							transparent 25.5%,
							transparent 75%,
							#21212177 75%,
							#21212177
						);
					background-size: 30px 53px;
					background-position: 0 0, 0 0, 15px 26px, 15px 26px, 0 0, 15px 26px;
				}
			}
		}
	}

	.cover-image {
		background: white;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;

		&::before {
			background: rgba(black, 0.5);
			border-radius: $border-radius * 2;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		img {
			border-radius: $border-radius * 2;
			height: 100%;
			opacity: 0.5;
			object-fit: cover;
			width: 100%;
		}
	}

	.inner-content {
		text-align: center;
		top: 40%;
		position: relative;
		z-index: 1;
	}

	h2 {
		color: white;
		font-size: 36px;
		padding-top: 50px;
		text-shadow: -1px -1px 0 $primary-color, 1px -1px 0 $primary-color, -1px 1px 0 $primary-color,
			1px 1px 0 $primary-color, 2px 2px 0 $primary-color, 3px 3px 0 $primary-color;

		@include mobile {
			font-size: 30px;
		}
	}

	.description {
		color: white;
		font-size: 18px;
		margin: 20px 0 0;
		text-shadow: 2px 1px 2px rgba(black, 0.75);
	}

	.made-by {
		align-items: center;
		display: flex;
		justify-content: center;
		font-size: 14px;
	}

	.meta {
		align-items: center;
		background: darken(white, 2.5%);
		border: 2px solid darken(white, 7.5%);
		border-radius: $border-radius * 2;
		bottom: 0;
		display: flex;
		font-size: 14px;
		justify-content: space-evenly;
		margin: 0 auto 0;
		padding: 10px 10px 10px 15px;
		position: relative;
		transform: translateY(calc(50% + 25px));

		> div {
			align-items: center;
			display: flex;

			+ div {
				margin-left: 35px;
			}

			p {
				margin-left: 5px;
			}
		}
	}

	button {
		display: inline-flex;
		margin-left: auto;

		&.disabled {
			font-size: 14px;
		}
	}

	.alert {
		margin-top: 50px;
	}

	a {
		color: rgba($font-color, 0.75);
		display: block;
		margin-top: 10px;

		&:hover {
			color: $font-color;
		}
	}
}
</style>
