<template>
	<img
		:id="id"
		:data-src="lazySrc"
		:data-srcset="lazySrcset"
		:data-image-loaded="loaded"
		:style="style"
		:alt="alt"
		class="lazy-image"
	/>
</template>

<script>
import lozad from 'lozad'

export default {
	name: 'LazyImage',
	props: {
		backgroundColor: {
			type: String,
			default: '#efefef',
		},
		id: {
			type: String,
		},
		alt: {
			type: String,
			default: null,
		},
		height: {
			type: Number,
			default: null,
		},
		lazySrc: {
			type: String,
			default: null,
		},
		lazySrcset: {
			type: String,
			default: null,
		},
		width: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: true,
			loaded: false,
		}
	},
	computed: {
		aspectRatio() {
			if (!this.width || !this.height) return null

			return (this.height / this.width) * 100
		},
		style() {
			const style = { backgroundColor: this.backgroundColor }
			const applyAspectRatio = this.loading && this.aspectRatio

			if (applyAspectRatio) {
				style.height = 0
				style.paddingTop = `${this.aspectRatio}%`
			}

			return style
		},
	},
	mounted() {
		const setLoadingState = () => {
			this.loading = false
			this.loaded = true
		}
		this.$el.addEventListener('load', setLoadingState)
		this.$once('hook:destroyed', () => {
			this.$el.removeEventListener('load', setLoadingState)
		})

		const observer = lozad(this.$el, { enableAutoReload: true })
		observer.observe()
	},
}
</script>

<style lang="scss" scoped>
.lazy-image {
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	vertical-align: middle;
	width: 100%;

	&[data-image-loaded='true'] {
		animation-name: showLazyImg;
		animation-duration: 1s;
		animation-fill-mode: both;
	}
}

@keyframes showLazyImg {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>
