<template>
	<div class="image-component">
		<div
			v-if="overlay"
			class="overlay"
			@click="
				expanded = true
				toggleNoScroll()
			">
			<Icon name="expand" fill="white" />
		</div>

		<LazyImage :id="id" :height="height" :width="width" :lazy-src="src" :alt="alt" />

		<transition name="fade">
			<Lightbox
				v-if="expanded"
				type="image"
				:src="src"
				:alt="alt"
				@collapse="
					expanded = false
					toggleNoScroll()
				" />
		</transition>
	</div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue'
import Lightbox from '@/components/Lightbox.vue'
import Icon from './Icon.vue'

export default {
	components: {
		LazyImage,
		Lightbox,
		Icon,
	},

	props: {
		id: { type: String },
		alt: { type: String },
		height: { type: Number },
		overlay: { type: Boolean, default: false },
		src: { type: String, required: true },
		width: { type: Number },
	},

	data() {
		return {
			expanded: false,
		}
	},

	methods: {
		toggleNoScroll() {
			const classList = document.body.classList

			if (classList.contains('no-scroll')) {
				classList.remove('no-scroll')
			} else {
				classList.add('no-scroll')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.image-component {
	border-radius: $border-radius;
	height: 100%;
	min-width: 300px;
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 1;

	&:hover {
		.overlay {
			opacity: 1;
		}
	}

	.overlay {
		align-items: center;
		background: rgba(black, 0.25);
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: $transition;
		width: 100%;
		z-index: 2;

		::v-deep .icon i {
			transform: scale(125%);
		}
	}

	img {
		position: relative;
		z-index: 1;
	}
}
</style>
